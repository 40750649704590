import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2
} from "@angular/core";
import { ConfigService } from "../services/config.service";
import { Observable, Subscription } from "rxjs";
import { CustomizerService } from '../services/customizer.service';
import { HttpClient } from "@angular/common/http";
import swal from 'sweetalert2';

@Component({
  selector: "app-customizer",
  templateUrl: "./customizer.component.html",
  styleUrls: ["./customizer.component.scss"]
})
export class CustomizerComponent implements OnInit, OnDestroy {
  @ViewChild("customizer") customizer: ElementRef;

  size: string;
  isBgImageDisplay: boolean = true;
  isOpen = true;
  public config: any = {};
  layoutSub: Subscription;
  ApiMsg: string = "";


  constructor(
    private renderer: Renderer2,
    private configService: ConfigService,
    public customizerService: CustomizerService,
    private http: HttpClient,
  ) {

    this.config = this.configService.templateConf;
    this.isOpen = !this.config.layout.customizer.hidden;


    if (this.config.layout.sidebar.size) {
      this.size = this.config.layout.sidebar.size;
    }
  }

  @Output() directionEvent = new EventEmitter<Object>();

  ngOnInit() {

  }


  changeSidebarWidth(value) {
    this.size = value;
    this.customizerService.changeSidebarWidth(value);
  }

  toggleCustomizer() {
    if (this.isOpen) {
      this.renderer.removeClass(this.customizer.nativeElement, "open");
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.customizer.nativeElement, "open");
      this.isOpen = true;
    }
  }

  closeCustomizer() {
    this.renderer.removeClass(this.customizer.nativeElement, "open");
    this.isOpen = false;
  }

  bgImageDisplay(e) {
    if (e.target.checked) {
      this.isBgImageDisplay = true;
    } else {
      this.isBgImageDisplay = false;
    }
    //emit event to FUll Layout
    this.customizerService.bgImageDisplay(e);
  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  SaveTheamSetting() {
    // this.config.layout.variant = "Dark";
    console.log("Save Current Theam Setting");
    // console.log(this.config.layout.sidebar.collapsed);
    console.log(this.config);


    this.ApiPostCreateJob().subscribe((res: any) => {
      console.log(res);
      this.ApiMsg = res.errMsg;
     this.swlAlart()

    });




    // this.customizerService.switchLayout("Dark", true);

  }


  swlAlart() {
    swal.fire({
      title: 'Your Theam Setting Stored Successfully',
      text: 'this.ApiMsg',
      imageUrl: 'assets/img/icons/thumbs-up.jpg',
      imageWidth: 400,
      imageHeight: 300,
      imageAlt: 'Custom image',
      allowOutsideClick: false,
      confirmButtonText: 'Ok',
      showConfirmButton: true,

      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
      // willClose: this.JobSuccessClose,
    }).then((result) => {
      // let navUrl = "/shopping/jw/jobdetails?jwno=" + this.JobNoNew;
      // console.log("Dialog Close Success Job No :" + this.JobNoNew + "and Url : " + navUrl)
      // this.redirecToJobDetails();
      // this.router.navigate([navUrl]);
      
      this.isOpen = !this.config.layout.customizer.hidden;
    });
  }

  ApiPostCreateJob(): Observable<any> {

    console.log(this.config);

    return this.http.post<any>("/Cust/UpdateWebTheam-POST", {
      "backgroundColor": this.config.layout.sidebar.backgroundColor,
      "backgroundImage": this.config.layout.sidebar.backgroundImage,
      "backgroundImageURL": this.config.layout.sidebar.backgroundImageURL,
      "collapsed": this.config.layout.sidebar.collapsed,
      "hidden": this.config.layout.customizer.hidden,
      "menuPosition": this.config.layout.menuPosition,
      "size": this.config.layout.sidebar.size,
      "type": this.config.layout.navbar.type,
      "variant": this.config.layout.variant
    })

  }


}

<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <h6 class="nav-item mr-2 d-none d-lg-block" style="padding-right: 10px;">{{runningWatch | date : 'dd/MM/yyyy hh:mm ss a' }}</h6>
      <!-- <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div> -->
      <!-- <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)"> -->
      <!-- <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li> -->
      <!-- <li class="nav-item nav-search">
          <a class="nav-link nav-link-search" id="navbar-search" href="javascript:"
            (click)="toggleSearchOpenClass(true)"><i class="ft-search font-medium-3"></i></a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input [formControl]="control" #search class="input" type="text" placeholder="Search Products..."
              tabindex="0" autofocus data-search="template-search" [appAutocomplete]="autocomplete"
              (keyup.enter)="onEnter()" (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" />
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent>
                <ng-container *ngIf="(listItems | filter: control.value) as result">
                  <app-option #searchResults *ngFor="let option of result" [url]="option.url" [value]="option.name"
                    (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()">
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length">No results found.</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>
      </ul> -->
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/shopping/jw/dashboard']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt='{{LogoAltText}}' [src]="logoUrl"></div><span
                    class="text">{{appShortName}}</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <!-- <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown >
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><img class="langimg selected-flag"
                [src]="selectedLanguageFlag" alt="flag" /><span
                class="selected-language d-md-flex d-none">{{selectedLanguageText}}</span></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="ChangeLanguage('en')"><img
                  class="langimg mr-2" src="./assets/img/flags/us.png" alt="flag" /><span
                  class="font-small-3">English</span></a><a class="dropdown-item" href="javascript:;" data-language="es"
                (click)="ChangeLanguage('es')"><img class="langimg mr-2" src="./assets/img/flags/es.png"
                  alt="flag" /><span class="font-small-3">Marathi</span></a><a class="dropdown-item" href="javascript:;"
                data-language="pt" (click)="ChangeLanguage('pt')"><img class="langimg mr-2"
                  src="./assets/img/flags/pt.png" alt="flag" /><span class="font-small-3">Portuguese</span></a>
              <a class="dropdown-item" href="javascript:;" data-language="de" (click)="ChangeLanguage('de')"><img
                  class="langimg mr-2" src="./assets/img/flags/de.png" alt="flag" /><span
                  class="font-small-3">German</span></a>
            </div>
          </li> -->

          <!-- Below Code Have Bell Notification -->
          <!-- <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle><i class="ft-bell font-medium-3"></i><span
                class="notification badge badge-pill badge-danger">4</span></a>
            <ul ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">7 New
                      Notification</span>
                  </div>
                  <span class="text-bold-400 cursor-pointer">Mark all as read</span>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <a class="d-flex justify-content-between" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-20.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Kate Young</span><small class="grey lighten-1 font-italic float-right">5 mins
                          ago</small>
                      </h6>
                      <small class="noti-text">Commented on your photo</small>
                      <h6 class="noti-text font-small-3 m-0">
                        Great Shot John! Really enjoying the composition on this
                        piece.
                      </h6>
                    </div>
                  </div>
                </a>
                <a class="d-flex justify-content-between" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-11.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Andrew Watts</span><small class="grey lighten-1 font-italic float-right">49 mins
                          ago</small>
                      </h6>
                      <small class="noti-text">Liked your album: UI/UX Inspo</small>
                    </div>
                  </div>
                </a>
                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">
                  <div class="media d-flex align-items-center py-0 pr-0">
                    <div class="media-left">
                      <div class="mr-3">
                        <img src="assets/img/icons/sketch-mac-icon.png" alt="avatar" height="45" width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">Update</h6>
                      <small class="noti-text">MyBook v2.0.7</small>
                    </div>
                    <div class="media-right">
                      <div class="border-left">
                        <div class="px-4 py-2 border-bottom">
                          <h6 class="m-0 text-bold-600">Update</h6>
                        </div>
                        <div class="px-4 py-2 text-center">
                          <h6 class="m-0">Close</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-primary bg-lighten-3 mr-3 p-1">
                        <span class="avatar-content font-medium-2">LD</span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Registration done</span><small class="grey lighten-1 font-italic float-right">6 hrs
                          ago</small>
                      </h6>
                    </div>
                  </div>
                </a>
                <div class="cursor-pointer">
                  <div class="media d-flex align-items-center justify-content-between">
                    <div class="media-left">
                      <div class="media-body">
                        <h6 class="m-0">New Offers</h6>
                      </div>
                    </div>
                    <div class="media-right">
                      <div class="custom-switch custom-control">
                        <input type="checkbox" class="custom-control-input" id="custom-switch-1111" checked>
                        <label class="custom-control-label mr-1" for="custom-switch-1111">
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between cursor-pointer read-notification">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-danger bg-lighten-4 mr-3 p-1">
                        <span class="avatar-content font-medium-2"><i class="ft-heart text-danger"></i></span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Application approved</span><small class="grey lighten-1 font-italic float-right">18 hrs
                          ago</small>
                      </h6>
                    </div>
                  </div>
                </div>
                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-6.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Anna Lee</span><small class="grey lighten-1 font-italic float-right">27 hrs ago</small>
                      </h6>
                      <small class="noti-text">Commented on your photo</small>
                      <h6 class="noti-text font-small-3 text-bold-500 m-0">
                        Woah!Loving these colors! Keep it up
                      </h6>
                    </div>
                  </div>
                </a>
                <div class="d-flex justify-content-between cursor-pointer read-notification">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-info bg-lighten-4 mr-3 p-1">
                        <div class="avatar-content font-medium-2">
                          <i class="ft-align-left text-info"></i>
                        </div>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Report generated</span><small class="grey lighten-1 font-italic float-right">35 hrs
                          ago</small>
                      </h6>
                    </div>
                  </div>
                </div>
                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-7.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Oliver Wright</span><small class="grey lighten-1 font-italic float-right">2 days
                          ago</small>
                      </h6>
                      <small class="noti-text">Liked your album: UI/UX Inspo</small>
                    </div>
                  </div>
                </a>
              </li>
              <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">
                  Read All Notifications
                </div>
              </li>
            </ul>
          </li> -->

          <!-- <h6 style="padding-right: 10px;">{{runningWatch | date : 'dd/MM/yyyy hh:mm ss a' }}</h6> -->
          <!-- <h6>{{timerWatch$ | async }}</h6> -->


          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex mr-2">
                <span class="text-right" style="font-size: large; font-weight: bold;"> <b>{{userData.userFirstName}}</b>
                </span>
                <span class="text-right">  {{userData.custID}}</span>
              </div>
              <img class="avatar" [src]="Imaged" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <!-- <a class="dropdown-item" routerLink="/chat">
                <div class="d-flex align-items-center">
                  <i class="ft-message-square mr-2"></i><span>My Order</span>
                </div>
              </a> -->
              <a class="dropdown-item" routerLink="/shopping/jw/MyProfile">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>My Account</span>
                </div>
              </a>
              <!-- <a class="dropdown-item" routerLink="/inbox">
                <div class="d-flex align-items-center">
                  <i class="ft-mail mr-2"></i><span>My WishList</span>
                </div>
              </a> -->
              <!-- <a class="dropdown-item" routerLink="/inbox">
                <div class="d-flex align-items-center">
                  <i class="ft-mail mr-2"></i><span>My Inbox</span>
                </div>
              </a> -->
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>



          </li>
          <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i
                class="ft-align-right font-medium-3"></i></a>
          </li> -->
          <!-- <li>
              <a class="customizer-toggle bg-primary" id="customizer-toggle-icon"
              (click)="toggleCustomizer()">
              <i class="ft-settings font-medium-1 spinner white"></i>
            </a>
          </li> -->

        </ul>
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>

      </div>

    </div>
    <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
      data-toggle="collapse" (click)="toggleSidebar()">
      <i class="ft-menu font-medium-3"></i>
    </div>
  </div>





</nav>
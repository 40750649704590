export class GlobalComponent {
 
    public static appUrl: string = "https://www.xcellentent.com/shopping";
    public static appTitle: string = "Xcellent Enterprises Shopping Portal";
    public static appShortName: string = "Xcellent";
    public static appName: string = "Easy Smart Shop Online Shopping Portal";
    public static appLogoUrl: string = "assets/img/logo.png";
    public static appLogoDarkUrl: string = "assets/img/logo-dark.png";
    public static LogoAltText: string = "Xcellent Logo";
    // public static appEmail: string = "johndoe@example.com";
    public static LoginCustName: string = "SwaNirmit Technologies";
    public static LoginCustID: string = "50005";
    public static LoginCustPhoto: string = "Photo Encoded String";
 
}